<template>
    <div class="pagebox" v-loading="loading" element-loading-text="加载中...">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 专家服务 -->
        <div class="expertbox">
            <div class="expertcon">
                <!-- <div class="searchbox">
                    <div class="input">
                        <input type="text" placeholder="姓名 / 专业" v-model="expertname" maxlength="50" />
                        <div class="searchbtn"><i class="iconfont">&#xe635;</i>查询</div>
                    </div>
                    <div class="settlein" @click.prevent="$router.push('/expertsettlein')"><i class="iconfont i1">&#xe608;</i><span>专家入驻</span><i class="iconfont i2">&#xe608;</i></div>
                </div> -->
                <div class="expertlist">
                    <div class="ebox" v-for="(item,index) in expertdata" :key="index">
                        <div class="box" @click.prevent="$router.push('/expertdetail?id='+item.Id)">
                            <div class="img" :style="{background:'url('+item.HeadImageFileUrl+') no-repeat center center / cover'}"></div>
                            <div class="txt">
                                <div class="name">{{ item.TeacherName }}<span class="follow" v-show="false" :class="item.follow==true?'active':''" @click.prevent.stop="followbtn(item.follow,item.Id)"><i class="iconfont">&#xe61f;</i>{{item.follow==true?'已关注':'关注'}}</span><div class="clear"></div></div>
                                <div class="tips">
                                    <span v-for="(it,ind) in item.TeacherTagList" :key="ind">{{ it.TagName }}</span>
                                </div>
                            </div>
                            <div class="clear"></div>
                            <div class="mark">{{ item.Summary }}<span></span></div>
                            <div class="yuyue" @click.prevent.stop="toreservation(item.Id)">立即预约</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="pages" @click.prevent="pagesbtn()">
                    <i class="iconfont" v-if="expertdata.length==0&&pageIndex==1">&#xe67c;</i>
                    <span>{{pagetxt}}</span>
                </div>
            </div>
        </div>

        <!-- 专家预约 -->
        <Reservation :reservationid="reservationid" ref="expertreservation"></Reservation>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import Reservation from "../components/reservation/reservation.vue";
import API from "@/api/config";
export default{
    name:'Expert_List',
    components:{
        PHeader,
        PFooter,
        Reservation
    },
    data(){
        return{
            loading:false,
            pageIndex:1,
            pagetxt:'',
            expertname:'',
            reservationid:'',
            expertdata:[],
            HasNext:false
        }
    },
    mounted(){
        this.GetTeacherList();
    },
    methods:{
        //获取专家
        GetTeacherList(){
            this.loading=true;
            API.Web.GetTeacherList({pageIndex:this.pageIndex,pageSize:6}).then(res => {
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.expertdata.push(f);
                    });
                    this.HasNext=res.Data.HasNext;
                    if(this.HasNext){
                        this.pagetxt='点击加载更多';
                    }
                    else{
                        this.pagetxt='已经到底啦！';
                    }
                }
                else{
                    this.pagetxt='未找到专家信息！';
                }
                this.loading=false;
            });
        },
        //预约
        toreservation(id){
            this.$refs.expertreservation.reservationshow=true;
            this.$refs.expertreservation.layerclass='layershow';
            this.$refs.expertreservation.layerboxclass="layerboxshow";
            this.reservationid=id;
        },
        //关注
        followbtn(follow,id){
            let obj = this.expertdatacopy.find(item=>item.id==id);
            if(obj){
                if(follow){
                    obj.follow=false;
                }
                else{
                    obj.follow=true;
                }
            }
        },
        //加载更多
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.GetTeacherList();
                }
            }else{
                if(this.expertdata.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.expertbox{
    max-width: 1420px;
    margin: 20px auto 50px;
    user-select: none;
    .expertcon{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .searchbox{
            padding: 20px;
            height: 46px;
            background-color: #fff;
            border-bottom: 1px solid #ebe9e9;
            box-shadow: 0px 0px 3px 1px #ebeaea;
            .input{
                width: calc(100% / 2 - 10px);
                height: 46px;
                float: left;
                input{
                    float: left;
                    width: calc(100% - 102px);
                    height: calc(100% - 2px);
                    text-indent: 20px;
                    color: #666;
                    font-size: 16px;
                    line-height: 1;
                    line-height: 46px;
                    border: 1px solid #e0e0e0;
                    border-radius: 3px;
                }
                .searchbtn{
                    float: left;
                    width: 80px;
                    height: 46px;
                    background-color: rgba(var(--themecolor),0.45);
                    color: #555;
                    font-size: 16px;
                    text-align: center;
                    line-height: 46px;
                    margin-left: 20px;
                    border-radius: 3px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    i{
                        font-size: 16px;
                        margin-right: 5px;
                    }
                }
                .searchbtn:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),0.65);
                }
            }
            .settlein:hover{
                cursor: pointer;
                
                border: 2px solid rgba(var(--themecolor),0.8);
                color: #333;
            }
            .settlein{
                float: right;
                width: 200px;
                height: 42px;
                color: #555;
                line-height: 42px;
                border-radius: 30px;
                background-image: linear-gradient(to right, #fff, #eee, #fff);
                border: 2px solid rgba(var(--themecolor),0.5);
                background-color: #f9ede0;
                position: relative;
                transition: all 0.2s;
                -webkit-transition: all 0.2s;
                span{
                    position: absolute;
                    left: 55px;
                    letter-spacing: 3px;
                    font-size: 20px;
                    font-weight: bold;
                }
                i{
                    position: absolute;
                    font-size: 26px;
                    color: #dfad23;
                    top: 2px;
                }
                i.i1{
                    left: 15px;
                    transform: rotate(0deg);
                    animation:settle1 0.4s ease-in-out 0s infinite alternate;
                    -webkit-animation:settle1 0.4s ease-in-out 0s infinite alternate;
                    @-webkit-keyframes settle1 {
                        from {left: 15px;}
                        to {left: 20px;}
                    }
                    @keyframes settle1 {
                        from {left: 15px;}
                        to {left: 20px;}
                    }
                }
                i.i2{
                    right: 16px;
                    transform:rotateY(180deg);
                    animation:settle2 0.4s ease-in-out 0s infinite alternate;
                    -webkit-animation:settle2 0.4s ease-in-out 0s infinite alternate;
                    @-webkit-keyframes settle2 {
                        from {right: 15px;}
                        to {right: 20px;}
                    }
                    @keyframes settle2 {
                        from {right: 15px;}
                        to {right: 20px;}
                    }
                }
            }
        }
        .expertlist{
            margin: 10px 10px;
            .ebox{
                width: calc(100% / 2);
                height: 260px;
                float: left;
                .box:hover{
                    cursor: pointer;
                    box-shadow: 0px 0px 3px 1px rgba(var(--themecolor),0.4);
                }
                .box{
                    width: calc(100% - 20px);
                    height: 240px;
                    margin: 10px;
                    background-color: #fff;
                    box-shadow: 0px 0px 3px 1px #ebeaea;
                    border-radius: 5px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    .img{
                        width: 100px;
                        height: 100px;
                        float: left;
                        margin: 10px 15px;
                        overflow: hidden;
                        border-radius: 100px;
                    }
                    .txt{
                        float: right;
                        width: calc(100% - 140px);
                        .name{
                            color: #555;
                            font-size: 20px;
                            font-weight: bold;
                            margin-top: 18px;
                            .follow{
                                padding: 2px 8px;
                                font-size: 12px;
                                color: #666;
                                font-weight: normal;
                                border: 1px solid #ddd;
                                border-radius: 20px;
                                float: right;
                                margin-right: 20px;
                                i{
                                    margin-right: 5px;
                                    color: #bdbbbb;
                                }
                            }
                            .follow:hover{
                                cursor: pointer;
                                border: 1px solid rgba(var(--themecolor),0.4);
                            }
                            .follow.active{
                                i{
                                    color: rgb(var(--themecolor));
                                }
                            }
                        }
                        .tips{
                            width: 100%;
                            height: 50px;
                            overflow: hidden;
                            margin-top: 15px;
                            span{
                                font-size: 12px;
                                color: #6a6969;
                                background-color: #f9ede0;
                                margin-right: 10px;
                                padding: 2px 15px;
                                border-radius: 20px;
                                margin-bottom: 5px;
                                display: inline-block;
                                user-select: none;
                            }
                        }
                    }
                    .mark{
                        margin: 0px 20px;
                        color: #666;
                        font-size: 13px;
                        line-height: 20px;
                        height: 40px;
                        overflow: hidden;
                        position: relative;
                        span{
                            position: absolute;
                            right: 0px;
                            bottom: 0px;
                            height: 24px;
                            width: 80px;
                            background: linear-gradient(to right, rgba(255,255,255,0.1), rgba(255,255,255,1));
                        }
                    }
                    .yuyue{
                        margin: 20px 20px 0;
                        height: 40px;
                        background-color: rgba(var(--themecolor), 0.4);
                        color: #555;
                        font-size: 16px;
                        line-height: 40px;
                        text-align: center;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                    }
                    .yuyue:hover{
                        cursor: pointer;
                        background-color: rgba(var(--themecolor),0.6);
                    }
                }
            }
        }
        .pages{
            width: 100%;
            height: 50px;
            line-height: 50px;
            color: #666;
            font-size: 16px;
            text-align: center;
            letter-spacing: 1px;
            margin-bottom: 10px;
            user-select: none;
        }
        .pages:hover{
            color: rgb(var(--themecolor));
            cursor: pointer;
        }
    }
}
</style>